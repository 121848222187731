import { APP_BASE_HREF, CommonModule } from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import {
    PrivaSegmentConfig,
    PrivaSegmentCoreModule,
    PrivaSegmentUserService,
} from '@priva/analytics/segment';
import {
    AppShellConfiguration,
    AppShellModule,
    AppShellUtilitiesModule,
    Preloader,
    PrimaryNavigationService,
} from '@priva/appshell';
import { PrivaAuthCurrentUserBffModule, PrivaAuthCurrentUserService } from '@priva/auth/current-user';
import { PrivaAuthHttpBffModule, PrivaAuthHttpOptions } from '@priva/auth/http';
import { PrivaAuthSignoutModule } from '@priva/auth/signout';
import { PrivaDynamicModule } from '@priva/components/dynamic';
import { PrivaNotificationsModule } from '@priva/components/notifications';
import { PrivaErrorPageHandlerService } from '@priva/error-pages';
import { PrivaLocalizationModule, PrivaLocalizationOptions } from '@priva/localization';
import { PrivaScrollbarModule } from '@priva/utilities/scrollbar';
import { PrivaScrollToModule } from '@priva/utilities/scrollto';
import { PrivaToggleModule } from '@priva/utilities/toggle';
import { PrivaWindowEventsModule } from '@priva/utilities/window-events';

import { AppConfigurationModule, AppConfigurationService } from '@app/utilities';
import { AppRoutingModule } from 'app/app-routing.module';
import { AppComponent } from 'app/app.component';
import {
    getAnalyticsSegmentConfig,
    getAppShellConfig,
    getCustomAuthOptions,
    getCustomLocalizationOptions,
    getVersionBasedTranslationLoader,
} from 'app/app.config';
import { AppErrorHandlingModule, ErrorHandlerService } from 'app/common/error-handling';
import { NavigationModule } from 'app/common/routing/navigation.module';
import { AppPrimaryNavigationService } from 'app/common/routing/primary-navigation.service';
import { AppStateModule } from 'app/state/app-state.module';

import { ErrorHttpInterceptor as httpInterceptor } from './app/common/error-handling/error-handling-http.interceptor';
import { environment } from './environments/environment';

// const options = new PrivaAuthHttpOptions();
// const options = new PrivaLocalizationOptions();

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            CommonModule,
            BrowserModule,
            PrivaAuthCurrentUserBffModule.forRoot(),
            PrivaAuthHttpBffModule.forRoot({
                provide: PrivaAuthHttpOptions,
                useFactory: getCustomAuthOptions,
                deps: [AppConfigurationService],
            }),
            AppRoutingModule,
            PrivaDynamicModule,
            NavigationModule.forRoot(),
            AppConfigurationModule.forRoot(),
            PrivaWindowEventsModule.forRoot(),
            PrivaScrollbarModule.forRoot(),
            PrivaSegmentCoreModule.forRoot([
                {
                    provide: PrivaSegmentConfig,
                    useFactory: getAnalyticsSegmentConfig,
                    deps: [AppConfigurationService],
                },
                { provide: PrivaSegmentUserService, useExisting: PrivaAuthCurrentUserService },
            ]),
            AppShellModule.forRoot(
                {
                    provide: AppShellConfiguration,
                    useFactory: getAppShellConfig,
                    deps: [AppConfigurationService],
                },
                [
                    AppShellModule.provideDefaultFeatureToggleRouter(),
                    { provide: PrimaryNavigationService, useClass: AppPrimaryNavigationService },
                ],
            ),
            AppShellUtilitiesModule,
            PrivaToggleModule.forRoot(),
            PrivaNotificationsModule.forRoot(),
            PrivaScrollToModule.forRoot(),
            PrivaAuthSignoutModule,
            AppErrorHandlingModule,
            /**
             * Localization, Storage & Translate configuration
             */
            PrivaLocalizationModule.forRoot({
                provide: PrivaLocalizationOptions,
                useFactory: getCustomLocalizationOptions,
                deps: [AppConfigurationService],
            }),
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: getVersionBasedTranslationLoader,
                    deps: [HttpClient, PrivaLocalizationOptions],
                },
            }),
            AppStateModule,
        ),
        { provide: APP_BASE_HREF, useValue: '/' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: httpInterceptor,
            multi: true,
        },
        ErrorHandlerService,
        PrivaErrorPageHandlerService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
    .then(() => {
        // trigger preloader
        if (Preloader.appBootstrap) {
            Preloader.appBootstrap();
        }
    })
    /* eslint-disable-next-line no-console */
    .catch((err) => console.log(err));
